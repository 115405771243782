.my-calender-wrapper {
    width: 100%;
    max-width: 800px;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    box-shadow: 0px 3px 30px 0px #00000029;
    margin-top: 100px;
    border-radius: 12px;
}



.my-calender-wrapper .month-day {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 4px;
}

.my-calender-wrapper .month-day>div {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.date-div {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.date-div:hover {
    background-color: #0093f5;
    color: #fff;
    border-radius: 4px;
}

.dates_in_range {
    background-color: #0a60d17c;
    color: #fff;
}

.hight_date {
    background-color: hsl(199, 100%, 53%);

}


.selected-time-wrapper {
    position: relative;
}

.time-selected {
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 80px;
    overflow-y: scroll;
    position: absolute;
}

.time-selected>span {
    padding: 4px;
    background-color: #f0f0f0;
    color: #111111;
    font-size: 14px;
    text-align: center;
}

.time-selected>span:hover {
    background-color: #fff;
    cursor: pointer;
}



.time-selected::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(255, 255, 255);
}

.time-selected::-webkit-scrollbar-thumb {
    background-color: rgb(145, 145, 145);
    scrollbar-width: 4px;
    border-radius: 8px;
}




/* ============================================================================================= */



.date-picker {
    display: flex;
    flex-wrap: wrap;
}

.date {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.date:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #007bff;
    color: #fff;
}



/* ================================OVER RIDE DATE PICKER ================================ */



#date-range-picker-wrapper .rdrCalendarWrapper {
    width: 100%;
}







.rdrWeekDay {
    /* color: #5a5a5a ; */
    color: #5a5a5a !important;
    font-size: 15px;
    font-weight: 500 !important;
}


.rdrDayNumber>span {
    color: #5a5a5a;
    font-size: 15px;
    font-weight: 500;
}


.rdrMonths {
    width: 100%;
}

.rdrMonth {
    /* width: 50%; */
    flex-grow: 1;
}




.rdrDateDisplayWrapper {
    display: none !important;
}






.rdrDay {
    margin-top: -4px !important;
    height: 44px !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    height: 200px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 6.5px 14px !important;
    width: 54px !important;
}