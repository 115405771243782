@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/ibm-plex-sans");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');



* {
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  /* display: none; */
  /* -webkit-appearance: none; */
  color: transparent;
}

.removeBack .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
  border-radius: 4px;
}

#mainInput input {
  font-size: 12px;
}

#mainInput {
  color: white;
  font-weight: 100;
  fill: #8495c2;
}

#mainInput svg {
  fill: #8495c2;
}

#mainInput svg {
  fill: #8495c2;
}

#radioButtonFill svg {
  fill : #8495c2 !important;
}

.MuiInputBase-root .MuiOutlinedInput-root {
  flex-direction: row-reverse;
}

#hideIt {
  opacity: 100% !important;
}

.form-control {
  height: 46px !important;
  width: 100% !important;
  background-color: #f0f0f0 !important;
  border: 0px !important;
}

#adminForm .form-control {
  background-color: rgb(203 213 225) !important;
  height: 40px !important;
}

.flag-dropdown {
  background-color: #f0f0f0 !important;
  border: 0px !important;
}

#adminForm .flag-dropdown {
  background-color: rgb(203 213 225) !important;
}
select {
  -webkit-appearance: none;
  border: none;
  outline: none;
}

#addSelectPage{
  -webkit-appearance: menulist-button;
  appearance: menulist-button;
  border: 1.5px solid #8495c2 !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrCalendarWrapper {
  width: 570px;
}

#inputValues svg {
  fill: "#8495c2" !important;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.missing-image {
  width: 233px;
  height: 187px;
  background: url("./static/staticcarimage.png") center center no-repeat;
}

li {
  list-style-type: circle;
}

#blogsDiv h1{
font-size: 28px;
margin-bottom: 10px;
font-weight: 700;
}

#blogsDiv p{
font-size: 16px;
margin-bottom : 10px;
}

#blogsDiv img{
margin-top: 10px;
margin-bottom: 10px;
border-radius: 1rem;
}

@layer components{
  .inputStyleDashboard{
    @apply p-2 bg-slate-300 rounded w-full outline-none focus-visible:border-b-2 focus-visible:border-[#0f0a34] placeholder:normal-case mr-8 transition-all duration-300
  }
}


.blog-detail-para p {
  font-weight: 100 !important;
  color:#474747;
  word-wrap: break-word;
  font-size: small;
}
.blog-detail-para ul {
  padding-left: 20px;
  font-weight: 100 !important;
  color:#474747;
  word-wrap: break-word;
  font-size: small;
}

.blog-detail-para a:visited {
  color: #074a92;
  text-decoration: underline;
}
.blog-detail-para a:hover {
  text-decoration: underline; 
}
/* unvisited link */
.blog-detail-para a:link {
  color: #207cdf; 
}

.blog-detail-para h1, .blog-detail-para h1 strong{
  font-size: 32px ;
  line-height: 1.6;
}
.blog-detail-para h2, .blog-detail-para h2 strong{
  font-size: 24px ;
  line-height: 1.5;
}
.blog-detail-para h3, .blog-detail-para h3 strong{
  font-size: 20px ;
  line-height: 1.5;
}
.blog-detail-para h4, .blog-detail-para h4 strong{
  font-size: 16px ;
  line-height: 1.5;
}
.blog-detail-para h5, .blog-detail-para h5 strong{
  font-size: 14px ;
  line-height: 1.4;
}
.blog-detail-para h6, .blog-detail-para h6 strong{
  font-size: 12px ;
  line-height: 1.4;
}


.blog-detail-para  strong{
  font-weight: bold !important;
  color: #010101;
  font-size: 15px ;
}