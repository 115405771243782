.bg-image{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-vehicle-fleet-image {
    background-image: url("../../images/vehicle_fleet_bg_img.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}