
.bg-tilt{
    background: linear-gradient(314.83deg, #03045E 2.14%, #00D5FF 142.92%);

}

.price-range-wrapper{

}



.price-range-wrapper .css-eg0mwd-MuiSlider-thumb:before {

    width: 70% !important;
    height: 70% !important;
   background-color: #fff !important;
}


.price-range-wrapper .MuiSlider-thumb .MuiSlider-thumbSizeMedium .MuiSlider-thumbColorPrimary .MuiSlider-thumb .MuiSlider-thumbSizeMedium .MuiSlider-thumbColorPrimary .css-eg0mwd-MuiSlider-thumb{

    /* background-image: linear-gradient(114.92deg, #0077B6 0%, #00B4D8 100%); */
    
    
}