.bg-image {

 background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-client-wave-image {
    background-image: url("../../images/client_wave_img.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position-x: -92px !important;
    background-position-y: 48px !important; */
}