.bg-banner-image {

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


.feature-vehicle-bg-img {
    background-image: url("../../images/feature_vehicle_bg_img.svg");
}



.FeatureVehicleSlider .swiper-slide {
    overflow: hidden !important;
}

.FeatureVehicleSlider .swiper-pagination {
    position: unset !important;
    margin-top: 10px !important;
}

.FeatureVehicleSlider .swiper-pagination .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    background-color: #ffffff;
}