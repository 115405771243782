/* Skeleton styles*/
.skeleton-blog{
    margin-bottom: 2em;
    animation: scale 3s infinite;
   }
   .skeleton-author{
    background:#f5f0e7;
    height: 30px;
    margin-left: 1rem;
    border-radius: 6px;
    margin: 8px 8px;
   }
   .skeleton-image{
    height: 200px;
    border-radius: 6px;
    background: #f5f0e7;
    margin-top: 10px;
    margin: 8px 8px;
   }
   .skeleton-mid{
    height: 30px;
    border-radius: 6px;
    background: #f5f0e7;
    margin-top: 10px;    
    margin: 8px 8px;
   }
   .skeleton-footer{
    height: 30px;
    border-radius: 6px;
    background: #f5f0e7;
    margin-top: 10px;    
    margin: 8px 8px;
   }