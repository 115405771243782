.footer-link-heading {
    position: relative;
}

.left-gradient-side {
    background: linear-gradient(314.83deg, #03045E 2.14%, #00D5FF 142.92%);
}


.footer-link-heading::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-image: linear-gradient(114.92deg, #0077B6 0%, #00B4D8 100%);
}



.bg-image {

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-footer-image {
    background-image: url("../../images/footer_bg_img.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position-x: -92px !important;
       background-position-y: 48px !important; */
}