.bg-image {

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-document-image{
background-image: url("../../images/document_bg_img1.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

