.slick-slide {
  padding: 0 10px;
}

/* .popular-search {
background: linear-gradient(0deg, #092c85, #002992c4), linear-gradient(0deg, rgb(9 44 133 / 0%), rgb(9 44 133 / 0%));
position: relative;


} */


/* .popular-search::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("./images/popularSearchesBgImage.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  z-index: -1;
} */
