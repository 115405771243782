.car-booking-filter-tabs {
    overflow-x: scroll;
    padding-bottom: 12px;
}


.car-booking-filter-tabs::-webkit-scrollbar {
    background-color: rgb(230, 230, 230);
    /* scrollbar-width: 4px; */
    width: 24px;
    height: 6px;
}

.car-booking-filter-tabs::-webkit-scrollbar-thumb{
    background-color: rgb(109, 109, 109);
    width: 12px;
    border-radius: 32px;
}