#search-engine::-webkit-scrollbar{
    width: 8px;
    background-color: rgb(158, 158, 158);
    border-radius: 0px 4px 4px 0;
}


#search-engine::-webkit-scrollbar-thumb{
    background-color: #0066a5;
    border-radius: 12px;
    width: 6px;
}



/* #search-engine::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa; 
  }

#search-engine::-webkit-scrollbar-thumb {
    background: #000;
  } */